<!-- eslint-disable -->
<template>
    <div id="home" class="home">
        <div class="home-body">
            <SlotSelector v-if="!showSearchInput" @slotSelected="showFooter = $event"/>
            <section class="promos-section">
                <div v-if="!showSearchInput" class="promos-section-header">
                    <h3>{{$t("discount")}}</h3>
                    <div class="divider"></div>
                </div>
                <div class="promos swiper-container mySwiper1">
                    <div :class="{'swiper-wrapper': true, 'flex': promosSwiper != null}">
                        <CatalogProductBloc v-for="(product, id) in products" :key="id" :product="product" class="swiper-slide" @mounted="mountswiper('mySwiper1')" :productInSale="true" @basketChanged="updateBasket()" inCarousel/>
                    </div>
                </div>
            </section>
<!--            <section class="catalog-section">
                <div class="catalog-section-header">
                    <h3>{{$t("aisles")}}</h3>
                    <div class="divider"></div>
                </div>
                <div class="catalog-categories">
                    <CatalogCategoryBloc v-for="(category, id) in categories" :key="id" :category="category"/>
                </div>
            </section>-->
            <section class="promos-section">
                <div v-if="!showSearchInput" class="promos-section-header">
                    <h3>{{$t("favorites")}}</h3>
                    <div class="divider"></div>
                </div>
                <div>
                   <small>{{$t("productsOftenBuy")}}</small>
                </div>
                <div class="promos swiper-container mySwiper2">
                    <div style="padding-bottom: 50px;" :class="{'swiper-wrapper': true, 'flex': promosSwiper != null}">
                        <CatalogProductBloc v-for="(favoriteProduct, id) in favoriteProducts" :key="id" :product="favoriteProduct" class="swiper-slide" @mounted="mountswiper('mySwiper2')" @basketChanged="updateBasket()" inCarousel/>
                    </div>
                </div>
            </section>
        </div>
        <section v-if="showFooter" class="home-footer" >
            <FooterComponent :key="footerRefreshKey" :isOffline="isOffline" askBeforeCheckout/>
        </section>
    </div>
</template>

<script>
    /* eslint-disable */
    import CatalogProductBloc from "@/components/Food/CatalogProductBloc.vue"
    import CatalogCategoryBloc from "@/components/Food/CatalogCategoryBloc.vue"
    import FooterComponent from "@/components/Food/FooterComponent.vue"
    import SlotSelector from "@/components/Food/SlotSelector.vue"
    import {isNotNull} from "@/assets/js/Utils"
    var Utils = require("@/assets/js/Utils")
    var Basket = require("@/assets/js/Food/Basket")
    import { Swiper } from 'swiper'
    import {getChildByParentCategory, getProductsByCategory,getProductsById} from "@/assets/js/Catalog"


    export default {
        name: "Home",
        props: ['isOffline', 'showSearchInput'],
        components: { CatalogProductBloc, CatalogCategoryBloc, SlotSelector, FooterComponent },
        data () {
            return {
                version: "",
                scantype: localStorage.getItem("DKC_scanType"),
                modalShow: false,
                base: localStorage.getItem("DKC_base"),
                city: "Foix",
                promosSwiper: null,
                categories: [],
                products: [],
                showFooter: false,
                footerRefreshKey : 'home-footer' + parseInt(Math.random()*100),
                page: {
                    // title: this.firstName ? this.$t("pages.home.title") + ", " +this.firstName : this.$t("pages.home.title"),
                    title: this.$t("pages.home.title"),
                    description: this.$t("pages.home.description"),
                    shopName: this.$t("pages.home.shopName"),
                    showBackBtn: false,
                    showFilterBtn: false
                },
                //favoriteProducts: JSON.parse(localStorage.getItem("IEC_favoriteProducts")) || [], // Modify for RTS 2023
                favoriteProducts: [],
                selectedFavoriteProducts: JSON.parse(localStorage.getItem("IEC_favoriteSelectedProductsId")) || [],
                firstName: null
            }
        },
        mounted(){
            this.version = Utils.getConfig().pwaVersion;
            this.firstName = localStorage.getItem("IEC_FIRSTNAME")

        },
        methods: {
            resetDemo(){
                Utils.clearCacheExceptCustomer(this);
                Basket.clearBasket();
                this.$router.push('/need/scan?needParameters=true');
            },
            goToCategoryProducts(category){
                this.$router.push(`/category/${category.id}`)
            },
            mountswiper(swiperClass){
                console.log("Mount swiper " + swiperClass)
                this.promosSwiper = new Swiper(`.${swiperClass}`, {
                    slidesPerView: "auto",
                    spaceBetween: 30,
                    freeMode: true
                });

            },
            updateBasket(){
                this.$parent.basketChange();
                this.footerRefreshKey = 'home-footer' + parseInt(Math.random()*100)
            },
            goToBasket(){
                if(this.$parent.basket.basketProducts.length > 0){
                    this.$router.push("/basket")
                }
            },
            async getCatInUrl(idCat){
                let newCat = idCat
                let response = await getProductsByCategory(newCat)
                if(response.status === 200){
                    this.products = response.data;
                }
                var favoriteProductsId = this.products.slice(-4)
                this.favoriteProducts = favoriteProductsId
               
                this.$parent.showLoader = false;
            }
        },
        created() {
            var isStandalone = Utils.isStandalone();
            var now = Date.now();
            var lastShow = localStorage.getItem("DKC_lastShowHelper");
            var deviceType = localStorage.getItem("IEC_deviceType");
            
            // ajouter ?cId=idcategorie dans l'url
            const newCategory = this.$route.query.cId

            this.$parent.showLoader = true;

            this.$parent.checkDemonstrationLogo();
            if (newCategory) {
                sessionStorage.setItem('updateProductsWithNewCategory', newCategory);
            }
            if (sessionStorage.getItem('updateProductsWithNewCategory')) {
                this.getCatInUrl(sessionStorage.getItem('updateProductsWithNewCategory'))
                 
            }else{
                getChildByParentCategory( 1555873, async categories => {
                    this.categories = categories.reverse()

                    let products = []
                    /*let response1 = await getProductsByCategory(1555877)
                    if(response1.status === 200){
                        products = response1.data
                    }

                    //New products IEC
                    let response2 = await getProductsByCategory(1555964)
                    if(response2.status === 200){
                        let productsToAdd = [79652, 79662, 79657]
                        let newProduct = response2.data.filter(product => productsToAdd.indexOf(product.id) !== -1)
                        products.push(...newProduct)
                    }*/

                    //Modify for RTS 2023
                  
                    
                    let response = await getProductsByCategory(1555975)
                    if(response.status === 200){
                        let productsToAdd = [79739, 79740, 79741, 79742]
                        let newProduct = response.data.filter(product => productsToAdd.indexOf(product.id) !== -1)
                        products.push(...newProduct)
                    }

                    this.products = products

                    this.$parent.showLoader = false;
                })


                var favoriteProductsId = [79735, 79736, 79737, 79738];
                favoriteProductsId.forEach(favoriteProductId => {
                    if(!this.favoriteProducts.find(favoriteProduct => favoriteProduct.id === favoriteProductId)){
                        getProductsById(favoriteProductId, product => {
                            this.favoriteProducts.push(product)
                            localStorage.setItem("IEC_favoriteProducts",JSON.stringify(this.favoriteProducts))
                        })
                    }

                });
            }
            /*console.log(Basket.getBasketCount());
            if(Basket.getBasketCount() == 0 ){
                this.favoriteProducts.forEach(element => {
                    if(this.selectedFavoriteProducts.includes(element.id) ){
                        Basket.addProductToLocalBasket(element);
                    }
                })
                this.$root.$emit("basketChgt")
            } */

        },
            
        watch:{
            showSearchInput(newVal){
                if(!newVal){
                    this.updatePageInfo();
                }
            },
            firstName(){
                // this.page.title = this.firstName ? this.$t("pages.home.title") + ", " +this.firstName : this.$t("pages.home.title")
                this.page.title = this.$t("pages.home.title")
                this.updatePageInfo();
            }
        }
    }
</script>

<style scoped>
.home-header{
    padding: 100px 16px 4px 16px;
    background: rgb(var(--hot-food-color));
    color: white;
    border-bottom-right-radius: 25px;
    position: fixed;
    z-index: 9;
    width: 100%;
    display: none;
}
.home-header p{
    font-size: 0.8rem;
}

.promos-section, .catalog-section{
    color: rgb(var(--hot-food-color));
    margin: 16px;
}

.swiper-slide{
    width: 250px;
}

.promos-section .promos-section-header, .catalog-section .catalog-section-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
}

.promos-section .promos-section-header h3, .catalog-section .catalog-section-header h3{
    margin: 0;
}

.promos-section .promos-section-header .divider, .catalog-section .catalog-section-header .divider{
    background: rgb(var(--hot-food-color));
    width: 68%;
    height: 1px;
    padding: 0 16px;
}
.promos{
    margin: 16px 0 8px 0;
}
.swiper-wrapper.flex{
    display: -webkit-box;
    width: 100% !important;
}

.catalog-categories{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 200px));
    grid-gap: 16px;
    margin: 16px 0;
    align-items: center;
    justify-content: center;
}

.home-footer{
    z-index: 100;
    width: 100%;
    background: #ffffff75;
    position: fixed;
    bottom: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
}
</style>
