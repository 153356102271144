<template>
    <div class="catalog-category-bloc-wrapper" @click="goToCategoryProducts(category)">
        <div class="category-img" :style="{backgroundImage: `url('${categoryImg}')`}"></div>
        <div class="category-info">
            <span class="category-name">{{translateName(category.name)}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "CatalogCategoryBloc", 
    props: {
        category: Object
    },
    methods:{
        goToCategoryProducts(category){
            this.$router.push(`/category/${category.id}`)
        }
    },
    computed:{
        categoryImg(){
            return `https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/catalog/category/image/${this.category.image}`
        }
    }
}
</script>
<style>
    .catalog-category-bloc-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .category-img{
        width: 100%;
        padding-top: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
    }
    .category-info{
        margin: 4px 0;
        color: grey;
        font-weight: bold;
    }
</style>