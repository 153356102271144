<template>
    <div :class="{'catalog-product-bloc-wrapper': true, 'in-carousel': inCarousel}">
        <div v-if="productInSale" class="promo-badge">
            <span>Promo !</span>
        </div>
        <div class="product-img" :style="{backgroundImage: `url('${productImg}')`}"></div>
        <div class="product-general-info">
            <div class="product-info">
                <span class="product-name">{{translateName(product.name)}}</span>
                <div class="additional-info">
                    <span class="product-size"></span>
                    <span v-if="productInSale" class="product-old-price">{{formatPrice(product.price*1.2)}}€</span>
                    <span v-else class="product-old-price"> </span>
                </div>
            </div>
            <div class="product-price-wrapper">
                <span class="product-price">{{formatPrice(product.price)}}€</span>
            </div>
        </div>
        
        <div class="product-footer">
            <div class="nutriscore" v-if="nutriscore" :style="{backgroundImage: `url('${require('@/assets/img/nutriscore/'+nutriscore+'.png')}')`}"></div>
            <div class="nutriscore unavailable" v-else :style="{backgroundImage: `url('${require('@/assets/img/nutriscore/unavailable.png')}')`}"></div>
            <div class="actions">
                <div v-if="qty < 1" class="basket-icon" @click="incrQty()">
                    <span class="icon-panier"></span>
                </div>
                <div v-else class="qty-controls">
                    <span class="icon-moins control-btn" @click="decrQty()"></span>
                    <span class="qty">{{qty}}</span>
                    <span class="icon-plus control-btn" @click="incrQty()"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {nutriscoreMixin} from "@/mixins/nutriscoreMixin"
var basket = require('@/assets/js/Food/Basket');
export default {
    name: "CatalogProductBloc", 
    mixins: [nutriscoreMixin],
    data() {
        return {
            qty: 0,
            nutriscore: null
        }
    },
    props:{
        product: Object,
        productInSale:{
            type: Boolean,
            default: false
        },
        inCarousel:{
            type: Boolean,
            default: false
        }
    },
    async created(){
        await this.fetchData(this.product.ean)
        this.nutriscore = this.getNutriscore()
    },
    mounted(){
        this.qty = basket.getProductQuantity(this.product)
        this.$emit("mounted")
    },
    methods:{
        incrQty: function() {
            basket.addProductToLocalBasket(this.product);
            this.updateBasket();
        },
        decrQty: function() {
            basket.decreaseProductFromLocalBasket(this.product);
            this.updateBasket();
        },
        updateBasket(){
            this.qty = basket.getProductQuantity(this.product)
            this.$emit("basketChanged")
        }
    },
    computed:{
        productImg(){
            if(this.product){ 
                return `https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/catalog/product/image/${this.product.image}` //URL A CHANGER
            }
        }
    }
}
</script>
<style>
    .catalog-product-bloc-wrapper{
        box-shadow: #00000014 0px 3px 10px;
        padding: 0;
        display: grid;
        grid-template-rows: minmax(0, 150px) 1fr;
        grid-template-columns: minmax(0, 100%);
        position: relative;
        border-radius: 10px;
        margin: 16px 8px;
        flex: 0 0 calc(50% - 16px);
        width: 90%;
    }
    .in-carousel{
        flex: 0 1 auto;
        width: 250px;
    }
    .catalog-product-bloc-wrapper .product-img{
        background-size: cover;
        border-radius: 10px;
        width: 100%;
    }
    .promo-badge{
        position: absolute;
        padding: 4px;
        color: white;
        background: red;
        border-radius: 7px;
        right: -8px;
        top: 8px;
    }
    .product-general-info{
        margin: 8px;
        display: flex;
        justify-content: space-between;
    }
    .product-general-info .product-info{
        flex-direction: column;
        align-items: flex-start;
        color: grey;
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .product-general-info .product-info .product-name{
        font-weight: bold;    
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .product-general-info .product-info .additional-info{
        display: flex;
        align-items: center;
    }
    .additional-info .product-old-price{
        font-size: 0.8rem;
        text-decoration: line-through;
        font-style: italic;
        margin: 0 8px;
        color: #0000005e;
    }
    .product-footer{
        display: flex;
        align-items: center;
        margin: 8px;
    }
    .basket-icon{    
        width: 32px;
        height: 32px;
        background: rgb(var(--hot-food-color));
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1.2rem;
    }
    .nutriscore{
        width: 50px;
        height: 50px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat
    }
    .nutriscore.unavailable{
        filter: grayscale(1);
    }
    .product-price{
        font-weight: bold;
    }
    .product-price-wrapper{
        width: 40%;
        text-align: end;
    }
    .qty-controls{
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .qty-controls .control-btn{
        margin: 0 8px;
        width: 24px;
        height: 24px;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
        box-shadow: #00000014 0px 3px 10px;
    }
    .actions{
        margin-left: auto;
    }
</style>